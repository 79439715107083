import React from "react";
import { Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import About from "../components/About/About";
import Layout from "../layout";

function AboutPage() {
  return (
    <Layout location="about" title="About">
      <div className="about-container">
        <Helmet title={`About | ${config.siteTitle}`} />
        <Row style={{ background: "white", minHeight: "calc(100vh - 104px)" }}>
          <About />
        </Row>
      </div>
    </Layout>
  );
}

export default AboutPage;
